angular.module('myApp.controllers')

    .controller('KeepcoolIndexEmailControll', ['$rootScope', '$scope', 'avLog', 'API_EVENTS', 'SweetAlert', 'blockUI', '$translate', 'ThemeConfig', 'dialogs', '$window', 'base64', 'ContactService', 'ErrorFieldService',
        function ($rootScope, $scope, avLog, API_EVENTS, SweetAlert, blockUI, $translate, ThemeConfig, dialogs, $window, base64, ContactService, ErrorFieldService) {

            var logger = avLog.getLogger('KeepcoolIndexEmailControll');
            logger.info('page loaded');

            //避免第一次整頁載入page loaded裡的初始化讀取二次
            $scope.waitingUpdateUserData = false;
            $scope.contact = {
                type: '0',
                name: null,
                mobilePhone: null,
                company: 'norequired',
                jobTitle: 'norequired',
                email: null,
                title: '請選擇你的問題?',
                content: null //base64 encode
            };
            /***
             *     /$$$$$$$$ /$$    /$$ /$$$$$$$$ /$$   /$$ /$$$$$$$$
             *    | $$_____/| $$   | $$| $$_____/| $$$ | $$|__  $$__/
             *    | $$      | $$   | $$| $$      | $$$$| $$   | $$   
             *    | $$$$$   |  $$ / $$/| $$$$$   | $$ $$ $$   | $$   
             *    | $$__/    \  $$ $$/ | $$__/   | $$  $$$$   | $$   
             *    | $$        \  $$$/  | $$      | $$\  $$$   | $$   
             *    | $$$$$$$$   \  $/   | $$$$$$$$| $$ \  $$   | $$   
             *    |________/    \_/    |________/|__/  \__/   |__/   
             *                                                       
             *                                                       
             *                                                       
             */


            $rootScope.$on(API_EVENTS.updateUserData, function (event, data) {
                logger.debug('API_EVENTS.updateUserData');
                $scope.waitingUpdateUserData = true;
                $scope.init();
            });


            /***
             *     /$$    /$$  /$$$$$$  /$$       /$$$$$$ /$$$$$$$ 
             *    | $$   | $$ /$$__  $$| $$      |_  $$_/| $$__  $$
             *    | $$   | $$| $$  \ $$| $$        | $$  | $$  \ $$
             *    |  $$ / $$/| $$$$$$$$| $$        | $$  | $$  | $$
             *     \  $$ $$/ | $$__  $$| $$        | $$  | $$  | $$
             *      \  $$$/  | $$  | $$| $$        | $$  | $$  | $$
             *       \  $/   | $$  | $$| $$$$$$$$ /$$$$$$| $$$$$$$/
             *        \_/    |__/  |__/|________/|______/|_______/ 
             *                                                     
             *                                                     
             *                                                     
             *     /$$$$$$$$ /$$$$$$$$ /$$   /$$ /$$$$$$$$         
             *    |__  $$__/| $$_____/| $$  / $$|__  $$__/         
             *       | $$   | $$      |  $$/ $$/   | $$            
             *       | $$   | $$$$$    \  $$$$/    | $$            
             *       | $$   | $$__/     >$$  $$    | $$            
             *       | $$   | $$       /$$/\  $$   | $$            
             *       | $$   | $$$$$$$$| $$  \ $$   | $$            
             *       |__/   |________/|__/  |__/   |__/            
             *                                                     
             *                                                     
             *                                                     
             */
            $scope.setInvalidTextOfContactName = function (invalidText) {
                logger.debug('setInvalidTextOfContactName: ' + invalidText);
                $scope.invalidTextOfContactName = invalidText;
            };
            $scope.setInvalidTextOfContactJobTitle = function (invalidText) {
                $scope.invalidTextOfContactJobTitle = invalidText;
            };
            $scope.setInvalidTextOfContactCompany = function (invalidText) {
                $scope.invalidTextOfContactCompany = invalidText;
            };
            $scope.setInvalidTextOfContactTitle = function (invalidText) {
                $scope.invalidTextOfContactTitle = invalidText;
            };
            $scope.setInvalidTextOfContactDesc = function (invalidText) {
                $scope.invalidTextOfContactDesc = invalidText;
            };

            /***
             *     /$$$$$$$$ /$$      /$$  /$$$$$$  /$$$$$$ /$$      
             *    | $$_____/| $$$    /$$$ /$$__  $$|_  $$_/| $$      
             *    | $$      | $$$$  /$$$$| $$  \ $$  | $$  | $$      
             *    | $$$$$   | $$ $$/$$ $$| $$$$$$$$  | $$  | $$      
             *    | $$__/   | $$  $$$| $$| $$__  $$  | $$  | $$      
             *    | $$      | $$\  $ | $$| $$  | $$  | $$  | $$      
             *    | $$$$$$$$| $$ \/  | $$| $$  | $$ /$$$$$$| $$$$$$$$
             *    |________/|__/     |__/|__/  |__/|______/|________/
             *                                                       
             *                                                       
             *                                                       
             */
            $scope.testMail = function () {
                logger.debug('testMail');
                var productionMode = $window.MYAPP.production;
                if (productionMode) {
                    $scope.contact = {
                        type: '0',
                        name: '蔡大痣',
                        mobilePhone: '056324803',
                        // '0963009935',
                        company: '鯊客科技',
                        jobTitle: '技術顧問',
                        email: 'bigd@sharktech.tw',
                        title: 'email測試',
                        content: null //base64 encode
                    };
                }
            };
            $scope.setContactUsEmail = function (contact) {
                logger.debug('setContactUsEmail');
                blockUI.start();
                var postBody = {
                    name: contact.name,
                    mobilePhone: contact.mobilePhone,
                    company: contact.company,
                    jobTitle: contact.jobTitle,
                    email: contact.email,
                    title: contact.title,
                    content: null
                };
                var contentOfPostBody = {
                    category: contact.type,
                    description: contact.content
                };
                contentOfPostBody = base64.encode(angular.toJson(contentOfPostBody));
                console.log(postBody);
                postBody.content = contentOfPostBody;
                console.log(postBody);
                ContactService.create(
                    postBody,
                    function (data, status) {
                        blockUI.stop();

                        SweetAlert.swal({
                                title: '信件已發送',
                                text: '你的信件已發送，請等候回覆',
                                type: 'success',
                                showCancelButton: false,
                                closeOnConfirm: true
                            },
                            function () {

                                if (typeof ($window.ga) != 'undefiend') {
                                    $window.ga('send', {
                                        hitType: 'event',
                                        eventCategory: '連結追踨',
                                        eventAction: 'contactus-寄送成功',
                                        //從哪來
                                        eventLabel: $window.location.href
                                    });
                                }


                            });
                    },
                    function (data, status) {
                        blockUI.stop();
                        if (status == 500) {

                            SweetAlert.swal(
                                '信件無法發送',
                                $translate.instant('SYSTEM_INFO.STATUS_500_DETAIL', {
                                    err: data.message
                                }),

                                'error');

                        } else if (status == 400) {
                            SweetAlert.swal(
                                '信件無法發送',
                                $translate.instant('SYSTEM_INFO.STATUS_400_DETAIL', {
                                    err: ErrorFieldService.parse(data)
                                }),
                                'error');
                        } else {
                            SweetAlert.swal(
                                '信件無法發送',
                                data.message,
                                'error');
                        }
                    });

            };


            /***
             *     /$$$$$$ /$$   /$$ /$$$$$$ /$$$$$$$$
             *    |_  $$_/| $$$ | $$|_  $$_/|__  $$__/
             *      | $$  | $$$$| $$  | $$     | $$   
             *      | $$  | $$ $$ $$  | $$     | $$   
             *      | $$  | $$  $$$$  | $$     | $$   
             *      | $$  | $$\  $$$  | $$     | $$   
             *     /$$$$$$| $$ \  $$ /$$$$$$   | $$   
             *    |______/|__/  \__/|______/   |__/   
             *                                        
             *                                        
             *                                        
             */

            $scope.init = function () {
                // $scope.testMail();
            };

            $scope.pageLoad = function () {
                if (!$scope.waitingUpdateUserData) {
                    $scope.init();
                } else {
                    logger.debug('waiting update user data event');
                }
            };

            $scope.pageLoad();


        }
    ]);